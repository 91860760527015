@import 'mixin';
@import 'variable';

/* base font
===============================================================*/
/*
10px = 63%   |   17px = 106%   |   28px = 175%   
11px = 69%   |   18px = 113%   |   30px = 188%
12px = 75%   |   19px = 119%   |   32px = 20%
13px = 81%   |   20px = 125%
14px = 88%   |   22px = 138%
15px = 94%   |   24px = 150%
16px = 100%  |   25px = 156%
*/

div#AllBox {
    font-size:100%;
    @include transition(all .5s);
}


/* HeaderBox
===============================================================*/
#header {
    .firstRow {
        .inner {
            .rightSide {
                .boxFont {
                    .fontSize {
                        li {
                            &.middle {
                                a {
                                    background: $blueLight;
                                    border: 1px solid $blueLight;
                                    color: #FFF;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.navSp {
    .top {
        .fontSize {
            .btnBox {
                a {
                    &.middle {
                        border: 1px solid #29a6e6;
                        background-color: #29a6e6;
                        color: #FFF;
                    }
                }
            }
        }
    }
}